<template>
  <a href="https://www.youtube.com/watch?v=Ys9Wmg696wQ" target="_blank" style="display: block">
    <div class="wrap">
      <div
        class="inner"
        :style="{ backgroundImage: 'url(' + image.placeholder + ')' }"
      >
        <ResponsiveImage
          :image="image"
          alt="Symbolbild 'Qualitätslabel'"
        ></ResponsiveImage>
        <div class="label">
         Qualitätslabel Holzbau Plus
          <p class="small">Hier geht’s zum Video</p>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import image from "@/assets/news/holzbau-plus.jpg";
export default {
  data() {
    return {
      image
    };
  }
};
</script>
<style scoped>
.wrap {
  position: relative;
  width: 100%;
  aspect-ratio: 4 / 1;
  overflow: hidden;
}
.small {
  font-size: 1.5rem;
}

.inner {
  position: absolute;
  min-width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.label {
  z-index: 2;
  position: absolute;
  top: 40%;
  min-height: 100%;
  min-width: 100%;
  text-align: center;
  font-size: 2rem;
  color: #fff;
  text-shadow: -1px 0 #636363, 0 1px #636363, 1px 0 #636363, 0 -1px #636363;

  line-height: 2rem;
}
.inner img {
  /* Disable "direct" actions */
  pointer-events: none;
  user-select: none;

  position: absolute;
  min-height: 100%;
  aspect-ratio: 4 / 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 500ms ease-in-out;
}
iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
@media (min-width: 839px) {
  .label {
    top: 50%;
  }
}
</style>
