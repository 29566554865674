<template>
  <router-link
    :to="{ name: link }"
    class="index-tile"
    :style="{ backgroundImage: 'url(' + image.placeholder + ')' }"
  >
    <ResponsiveImage :image="image" :alt="title"></ResponsiveImage>

    <div class="index-tile-label">
      <div class="index-tile-label-title">{{ title }}</div>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    title: String,
    link: String
  },
  data() {
    return {
      image: require("@/assets/index/" + this.link + ".jpg")
    };
  }
};
</script>
<style scoped>
.index-tile {
  display: block;
  cursor: pointer;
  user-select: none;
  background-size: cover;
}
.index-tile img {
  display: block;
  width: 100%;
  height: 100%;
}

.index-tile .index-tile-label {
  position: relative;
  overflow: hidden;
  height: 4rem;
  text-align: center;
  width: 100%;
  margin-top: -4rem;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1.25rem 1.5rem;
}

.index-tile-label-title {
  font-weight: bold;
  user-select: text;
}

@media (min-width: 839px) {
  .index-tile {
    flex-grow: 1;
  }
  .index-tile {
    width: 33.33333333%;
  }
}
</style>
