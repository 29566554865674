<template>
  <div id="app" :class="{ noJs: $isPreRendering }">
    <Header class="row" />
    <Jumbotron class="row-max" />
    <router-view :class="{ row: $route.path !== '/' }" />
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Jumbotron from "@/components/Jumbotron.vue";

export default {
  components: {
    Header,
    Footer,
    Jumbotron
  }
};
</script>

<style>
@import "../node_modules/normalize.css/normalize.css";

/* .. Relevant in combination with prerendered pages */
.noscript {
  background-color: #42b983;
  color: #ffffff;
  padding: 1rem;
  text-align: center;
}

body,
article,
section,
footer,
header,
div,
input,
nav,
article ol,
article ul,
blockquote,
figure {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: #f5f5f5;
  font-family: Arial, Verdana, sans-serif;
  line-height: 1.4rem;
  color: #636363;
}

a,
a:visited {
  color: #636363;
}

a:hover {
  color: #009fe3;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h2 > a {
  text-decoration: none;
}

h3 {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.8rem;
  margin: 0.5rem 0;
}
h4 {
  font-weight: bold;
  margin: 0 0 0.3rem 0;
}

.row {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.row-max {
  margin-left: 0;
  margin-right: 0;
}

p {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  hyphens: auto;
}

@media (min-width: 1100px) {
  .row,
  .row-max {
    width: 80%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
