<template>
  <div class="wrap" v-if="isNotNone">
    <iframe
      v-if="isContact"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2691.8235990555218!2d7.6372085999999975!3d47.571220499999995!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4791b75e7fbc11d5%3A0x79847da07923e746!2sR%C3%BCchligweg+65%2C+4125+Riehen%2C+Switzerland!5e0!3m2!1sen!2s!4v1415635805330"
      frameborder="0"
    ></iframe>
    <div
      v-else
      class="inner"
      :style="{ backgroundImage: 'url(' + responsiveImage.placeholder + ')' }"
    >
      <img
        alt="Jumbotron Image"
        ref="img"
        :style="{ opacity: imageLoaded ? 1 : 0 }"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imageLoaded: false
    };
  },
  computed: {
    isContact() {
      return this.$route.name === "contact";
    },
    isNotNone() {
      return this.$route.meta.jumbotron !== "none";
    },
    responsiveImage() {
      if (this.isContact || !this.isNotNone) {
        return undefined;
      }
      if (this.$route.meta.jumbotron) {
        return require("@/assets/jumbotron/" +
          this.$route.meta.jumbotron +
          ".jpg");
      }
      return require("@/assets/jumbotron/" + this.$route.name + ".jpg");
    }
  },
  watch: {
    responsiveImage: "smoothTransition"
  },
  mounted() {
    // Workaround, as using initial watcher does not work (refs are not populated yet)
    this.smoothTransition();
  },
  methods: {
    smoothTransition() {
      // make a smooth transition when the jumbotron image is being loaded
      // except on the contact page and when leaving the contact page
      if (this.isContact || !this.isNotNone) {
        return;
      }

      this.imageLoaded = false;
      this.$nextTick(function() {
        const image = this.$refs.img;
        image.srcset = this.responsiveImage.srcSet;
        image.src = this.responsiveImage.src;
        image.onload = () => {
          this.imageLoaded = true;
        };
      });
    }
  }
};
</script>

<style scoped>
/*
 .wrap, .inner and img are used to emulate "background" image to simplify
 responsive image handling using srcset.
*/
.wrap {
  position: relative;
  width: 100%;
  aspect-ratio: 3 / 1;
  overflow: hidden;
}

.inner {
  position: absolute;
  min-width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.inner img {
  /* Disable "direct" actions */
  pointer-events: none;
  user-select: none;

  position: absolute;
  min-height: 100%;
  min-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 500ms ease-in-out;
}
iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
</style>
