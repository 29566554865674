<template>
  <div>
    <div class="intro">
      <h2>{{ $content[category].index.title }}</h2>
      <div v-html="$content[category].index.__content"></div>
    </div>
    <section
      v-for="item in $content[category].items"
      :key="item.id"
      class="tile"
    >
      <div class="text">
        <h3 :id="item.id">{{ item.title }}</h3>
        <div
          class="content"
          @click="handleInternalLink"
          v-html="withObscuredLinks(item.__content)"
        ></div>
        <div v-if="item.additional_sites" class="additional_sites">
          <template v-for="additional_site in item.additional_sites">
            <router-link
              v-if="additional_site.name"
              class="additional_site"
              :key="additional_site.site"
              :to="{name: additional_site.name }"
              >{{ additional_site.title }}</router-link
            >
            <a v-else
              class="additional_site"
              :key="additional_site.site"
              :href="additional_site.url">{{ additional_site.title }}</a>
          </template>
        </div>
        <div v-if="item.id == 'jobs'">
          <p v-if="jobOffers().length == 0">
            Zurzeit ist unser Team komplett, doch über
            <router-link :to="{ name: 'contact' }"
              >Spontanbewerbungen</router-link
            >
            von engagierten Zimmerleuten freuen wir uns immer.
          </p>
          <ul v-else>
            <li v-for="item in jobOffers()" :key="item.id">
              <router-link :to="'/jobs/' + item.key">{{
                item.title
              }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <aside>
        <div v-if="item.layout === 'icons'" class="icons">
          <div v-for="icon in item.icons" :key="icon.url" class="icon">
            <a :href="icon.url" target="_blank" rel="noopener noreferrer">
              <img
                :src="contentAsset(category, item, icon.icon)"
                :alt="icon.alt"
              />
            </a>
          </div>
        </div>
        <Carousel v-else singleSlide="true">
          <Slide
            v-for="asset in item.assets"
            :key="assetKey(asset)"
            class="slide"
          >
            <ResponsiveImage
              v-if="typeof asset === 'string'"
              :image="contentAsset(category, item, asset)"
            ></ResponsiveImage>
            <ClickVideo
              v-else
              :src="asset.url"
              :image="contentAsset(category, item, asset.placeholder)"
            ></ClickVideo>
          </Slide>
        </Carousel>
      </aside>
      <div v-if="item.team">
        <transition-group name="person" tag="div" class="team" id="team">
            <template v-for="person in item.team">
              <div v-if="showAllTeam || !person.hidden" class="person" :key="person.name">
                <ResponsiveImage
                  :image="require('@/assets/team/' + person.image)"
                  :alt="person.name"
                ></ResponsiveImage>
                <h4>{{ person.name }}</h4>
                <p>{{ person.position }}</p>
                <p>{{ person.title }}</p>
              </div>
            </template>
        </transition-group>
        <div class="team-show-more">
          <span @click="toggleShowAllTeam()">{{ showAllTeam ? 'Weniger anzeigen' : 'Ganzes Team anzeigen' }}</span>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Carousel from "@/components/Carousel.vue";
import ClickVideo from "@/components/ClickVideo.vue";
import Slide from "@/components/Slide.vue";

export default {
  props: ["category"],
  components: {
    Carousel,
    ClickVideo,
    Slide
  },
  data() {
    return {
      showAllTeam: false
    };
  },
  methods: {
    toggleShowAllTeam() {
      if (this.showAllTeam) {
        this.showAllTeam = false;
        document.getElementById("team").scrollIntoView({ behavior: "smooth" });
      } else {
        this.showAllTeam = true;
      }
    },
    handleInternalLink(e) {
      const target = e.target.href;
      if (
        target.startsWith(window.location.origin) &&
        !target.endsWith(".pdf")
      ) {
        event.preventDefault();
        const relative = target.replace(window.location.origin, "");
        this.$router.push(relative);
      }
    }
  }
};
</script>

<style scoped>
.intro {
  text-align: center;
  margin: 3rem 0;
}

.intro p {
  font-size: 1.2rem;
  margin: auto;
}
@media (min-width: 839px) {
  .intro div {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}

.tile {
  display: flex;
  flex-flow: column;
}
.tile > div {
  margin-bottom: 1rem;
}

@media (min-width: 839px) {
  .tile {
    flex-flow: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .tile > aside {
    width: 50%;
    box-sizing: border-box;

    padding-right: 1rem;

    overflow: hidden;
  }
  .tile > .text {
    width: 50%;
    box-sizing: border-box;

    padding-left: 1rem;
  }
  .tile > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .tile > .team {
    width: 100%;
  }
}
.tile > aside > img {
  width: 100%;
  height: auto;
}

.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.icon {
  position: relative;
  background-color: #6363631a;
  padding-top: 25%;
  margin-top: 1rem;
  width: 30%;
}
.icon > a {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.icon img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}
.additional_sites {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  margin-top: 2rem;
}
.additional_sites > * {
  flex-shrink: 0;
  width: auto;
}
.additional_site {
  text-decoration: none;
  border: 2px solid #009fe3;
  border-radius: 0.25rem;
  padding: 0.25rem;
  font-size: 0.9rem;
  color: #009fe3;
}

.team {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.person {
  width: 45%;
  margin-bottom: 1rem;
}

.team-show-more {
  text-decoration: none;
  font-size: 0.9rem;
  color: #009fe3;
  display: flex;
  justify-content: center;
}

.team-show-more > span {
  border: 2px solid #009fe3;
  border-radius: 0.25rem;
  padding: 0.25rem;

  cursor: pointer;
}

@media (min-width: 839px) {
  .person {
    width: 32%;
  }
}
@media (min-width: 1100px) {
  .person {
    width: 32%;
  }
}

/* IMPORTANT: THESE NAME CHANGE WITH VUE3 */
.person-enter-active,
.person-leave-active {
  transition: all 0.5s ease;
}

.person-enter,
.person-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.person-enter-to,
.person-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>
